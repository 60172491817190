export const datasFr = {
  about: {
    title: "À propos de moi",
    content: {
      name: "Je m'appelle Valentin RAMEAU et je suis développeur sur Bordeaux.",
      situation: "Je suis actuellement en fin du cursus de master MIAGE (Méthodes Informatiques Appliquées à la gestion des entreprises) à l'Université de Bordeaux.",
      goal: "Je suis à la recherche d'un CDI après la fin de mes études dès octobre 2024.",
      motivations: "Je suis passionné par l'informatique et tout particulièrement par le développement depuis de nombreuses années. Ce qui m'intéresse le plus est de toujours apprendre de nouvelles choses.",
      resume: "voici mon cv",
      resume_link: "CV-fr"
    }
  },
  education: {
    title: "Parcours scolaire",
    content: [
      {
        title: "Master MIAGE",
        date: "2022 - 2024",
        graduated: "en cours",
        location: "Université de Bordeaux, Talence, France",
        description: "Le master MIAGE (Méthodes Informatiques Appliquées à la Gestion des Entreprises) permet d'acquérir une double compétence à la fois en informatique et en gestion afin de permettre aux diplômé d'être des experts de la gestion de SI ou de projet.",
        img: "/images/miage.png"
      },
      {
        title: "Licence 3 MIAGE",
        date: "2021 - 2022",
        graduated: "Diplômé en 2022",
        location: "Université de Bordeaux, Talence, France",
        description: "La licence 3 informatique parcours MIAGE (Méthodes Informatiques Appliquées à la Gestion des Entreprises) est le point d'entrée vers le master MIAGE et permet d'acquérir les compétence nécessaire au master MIAGE.",
        img: "/images/miage.png"
      },
      {
        title: "BTS SIO, spécialité SLAM",
        date: "2019 - 2021",
        graduated: "Diplômé en 2021",
        location: "Lycée Bertran de Born, Périgueux, France",
        description: "Le BTS SIO (Services Informatiques aux Organisations) forme des techniciens supérieurs en informatique. La spécialité SLAM (Solutions Logicielles et Applications Métiers) forme des techniciens capables de développer, de déployer et de maintenir des applications métiers.",
        img: "/images/bts.png"
      }
    ]
  },
  experiences: {
    title: "Expériences professionnelles",
    content: [
      {
        title: "Alternant développeur web chez Smile",
        company: "Smile",
        type: "Alternance",
        date: "2022-2024 (En cours)",
        location: "Bordeaux, France",
        description: "Alternance durant le Master MIAGE en tant que développeur web php et drupal. J'ai pu travailler sur des projets de différentes envergures.",
        img: "/images/smile.png"
      },
      {
        title: "Stagiaire Business Analyst chez Sopra Steria",
        company: "Sopra Steria",
        type: "Stage",
        date: "Avril 2022 - Juin 2022",
        location: "Mérignac, France",
        description: "Stage réalisé en fin de Licence 3 MIAGE en tant que Business Analyst. J'ai pu travailler sur la conception technique et la conception des fonctionnalités métier.",
        img: "/images/sopra.png"
      },
      {
        title: "Stagiaire développeur web à la polyclinique Francheville",
        company: "Polyclinique Francheville",
        type: "Stage",
        date: "Février 2021 - Mars 2021",
        location: "Périgueux, France",
        description: "Stage durant la deuxième année de BTS SIO en tant que développeur web. J'ai pu travailler sur la réalisation d'un outil de visualisation des bornes Wi-Fi dans le cadre de l'administration du SI de la polyclinique.",
        img: "/images/francheville.png"
      },
      {
        title: "Stagiaire développeur web chez DMAI",
        company: "DMAI",
        type: "Stage",
        date: "Juin 2020 - Juin 2020",
        location: "Sainte-Alvère, France",
        description: "Stage durant la première année de BTS SIO en tant que développeur WEB. J'ai pu travailler sur la réalisation d'un site web contenant un dashboard afin de suivre l'activité d'une chaîne de production de cartes électronique..",
        img: "/images/dmai.png"
      }
    ]
  },
  associative: {
    title: "Expériences associatives",
    content: [
      {
        title: "Vice-Président en charge des Junior-Entreprises",
        date: "2024-2025",
        location: "France",
        description: "Vice-président auprès de MIAGE Connection, chargé de suivre et accompagner les Junior-Entrprises du label Junior MIAGE Concept.",
        img: "/images/mc.png"
      },
      {
        title: "Chargé de mission Étude et commercial pour Junior MIAGE Concept Bordeaux",
        date: "2024",
        location: "Talence, France",
        description: "Chargé de mission Étude et commercial pour Junior MIAGE Concept Bordeaux. J'ai pour mission de réaliser le suivi de certaines études.",
        img: "/images/jmc.png"
      },
      {
        title: "Président de Junior MIAGE Concept Bordeaux",
        date: "2022 - 2024",
        location: "Talence, France",
        description: "Président de Junior MIAGE Concept Bordeaux. J'ai eu pour mission de représenter l'association, définir la stratégie et m'assurer de son suivi ainsi qu'accompagner les différents membres de l'association dans leurs tâches.",
        img: "/images/jmc.png"
      },
      {
        title: "Secrétaire Général et Responsable des Resources Humaines de Junior MIAGE Concept Bordeaux",
        date: "2022",
        location: "Talence, France",
        description: "En tant que Secrétaire Général, mon rôle était de m'assurer du bon fonctionnement de l'archivage ainsi que de la vie administrative de l'association. En tant que Responsable des Ressources Humaines, j'avais pour mission de suivre les membres de l'association de leur adhésion à la fin de leur mandat, que ce soit les administrateur, les chargés de mission ou encore les intervenants.",
        img: "/images/jmc.png"
      },
      {
        title: "Intervenant pour Junior MIAGE Concept Bordeaux",
        date: "2021-2022",
        location: "Talence, France",
        description: "En tant qu'intervenant pour Junior MIAGE Concept Bordeaux, j'ai eu pour tâche de réaliser un site WordPress utilisé en tant qu'outil interne pour le CFA de l'Université de Bordeaux.",
        img: "/images/jmc.png"
      },
      {
        title: "Création puis organisation anuelle du'un festival de culture populaire : Le Kawaii Festival à Bergerac",
        date: "2017-2021",
        location: "Bergerac, France",
        description: "J'ai pu participer à la création puis l'organisation de 3 éditions du Kawaii Festival à Bergerac. Cet événement a pour but de promouvoir la culture populaire japonaise ainsi que de proposer de nouvelles activités pour les Jeunes de Bergerac.",
        img: "/images/asso-nemiku.jpg"
      }
    ]
  },
  skills: {
    title: "Compétences",
    content: [
      {
        title: "langages",
        color: "#9A031E",
        skills: [
          "HTML",
          "CSS",
          "PHP",
          "Javascript",
          "C",
          "C++",
          "C#",
          "Java",
          "Python"
        ]
      },
      {
        title: "Frameworks et infrastructures",
        color: "#7D092F",
        skills: [
          "Symfony",
          "Drupal",
          "NodeJS",
          "React",
          "Express"
        ]
      },
      {
        title: "Base de Données",
        color: "#5F0F40",
        skills: [
          "SQL",
          "MySQL/MariaDB",
          "postgreSQL"
        ]
      },
      {
        title: "Outils",
        color: "#4B1F47",
        skills: [
          "Git",
          "Docker",
          "Unity",
          "Blender"
        ]
      },
      {
        title: "Gestion de projet",
        color: "#372E4E",
        skills: [
          "Méthode Agile",
          "Scrum"
        ]
      },
      {
        title: "Divers",
        color: "#0F4C5C",
        skills: [
          "Audit",
          "Gestion de projet",
          "Création et pilotage de la stratégie",
          "Mise en place et suivi d'un SMQ"
        ]
      }
    ]
  },
  projects: {
    title: "Projets",
    content: [
      {
        title: "EasyBudget",
        date: "En cours",
        img: "/images/easybudget.png",
        description: "Réalisation d'une application de gestion et de suivi de budget.",
        technologies : [
          "Nodejs",
          "Html",
          "Css",
          "TypeScript",
          "JavaScript",
          "Express",
          "Sequelize"
        ]
      },
      {
        title: "Portfolio",
        date: "2024",
        img: "/images/here.png",
        description: "Réalisation de mon portfolio sous forme de page web.",
        technologies : [
          "Nodejs",
          "Html",
          "Css",
          "JavaScript",
          "React"
        ]
      },
      {
        title: "GameFlow",
        date: "2024",
        img: "/images/gameflow.png",
        description: "Réalisation d'une application de simulation de règles de jeu sous la forme d'un workflow. Cet outil permet ainsi une meilleure conception et un meilleur équilibrage pour les Game Designers. Ce projet a été réalisé durant le Master 2 MIAGE",
        technologies : [
          "Nodejs",
          "Html",
          "Css",
          "JavaScript",
          "Sequelize",
          "MariaDB",
          "Express"
        ]
      },
      {
        title: "Corporate Conquest",
        date: "2024",
        img: "/images/cc.png",
        description: "Jeu réalisé durant le Master 2 MIAGE. Ce jeu est un jeu de gestion et de deckbuilding. Le but est de gérer une entreprise et de la faire prospérer en achetant des cartes qui représentent des employés ou des items.",
        technologies :  [
          "Nodejs",
          "Html",
          "Css",
          "JavaScript",
          "Sequelize",
          "MariaDB",
          "Express"
        ]
      }
    ]
  },
  hobbies: {
    title: "Centres d'intérêts",
    content: [
      {
        title: "Jeux vidéos",
        img: "/images/icons/controller.svg"
      },
      {
        title: "Jeux de sociétés",
        img: "/images/icons/dice.svg"
      },
      {
        title: "Jeux de rôles papier",
        img: "/images/icons/shield.svg"
      },
      {
        title: "Littérature",
        img: "/images/icons/book.svg"
      },
      {
        title: "Écriture",
        img: "/images/icons/pen.svg"
      },
      {
        title: "Théâtre",
        img: "/images/icons/mask.svg"
      },
      {
        title: "Cinéma",
        img: "/images/icons/film.svg"
      }
    ]
  },
  contact: {
    title: "Me contacter",
    email: "Votre email",
    subject: "Votre sujet",
    message: "Votre message",
    mailExample: "test@test.fr",
    subjectExample: "exemple",
    messageExample: "Mail d'exemple",
    successMessage: "Mail envoyé avec succès",
    errorMessage: "Veuillez remplir tous les champs",
    mailError: "Veuillez renseigner un email valide",
    send: "Envoyer"
  },
  days: [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi"
  ]
}