import { datasFr } from './Datas/fr';
import { datasEn } from './Datas/en';


let lang = 'fr';

export const getLang = () => {
    return lang;
}

export const getTranslation = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const newLang = urlParams.get('lang');
    if (newLang) {
        lang = newLang;
    }

    switch (lang) {
        case "fr":
            return datasFr;
        case "en":
            return datasEn;
        default:
            return datasFr;
    }
}