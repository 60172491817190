// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
    width: 125px;
    text-align: center;
    margin: 20px;
    cursor: pointer;
}
.icon img{
    width: 50px;
    /*height: 100px;*/
}

.start-menu .icon {
    margin: 10px;
    width: 100%;
}

.icon span {
    display: block;
    margin-top: 5px;
    color: white;
    font-size: 1.2em;
    text-align: center;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Icon/Icon.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,eAAe;AACnB;AACA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,cAAc;IACd,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".icon {\n    width: 125px;\n    text-align: center;\n    margin: 20px;\n    cursor: pointer;\n}\n.icon img{\n    width: 50px;\n    /*height: 100px;*/\n}\n\n.start-menu .icon {\n    margin: 10px;\n    width: 100%;\n}\n\n.icon span {\n    display: block;\n    margin-top: 5px;\n    color: white;\n    font-size: 1.2em;\n    text-align: center;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
