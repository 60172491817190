import React, { useState, useEffect, useRef } from 'react';
import apps from '../../config/apps';
import './Taskbar.css';
import Icon from "../Icon/Icon";
import LanguageSelector from "../LanguageSelector/LanguageSelector";

function Taskbar({ openWindow }) {
    const [startMenuOpen, setStartMenuOpen] = useState(false);
    const [currentTime, setCurrentTime] = useState(getFormattedDate());
    const startMenuRef = useRef(null);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(getFormattedDate());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (startMenuRef.current && !startMenuRef.current.contains(event.target)) {
                setStartMenuOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [startMenuRef]);

    const toggleStartMenu = () => {
        setStartMenuOpen(!startMenuOpen);
    };

    return (
        <div className="taskbar">
            <div className="start-menu" ref={startMenuRef} onClick={toggleStartMenu}>
                <img src="images/icons/power.svg" alt="Start" />
                {startMenuOpen && (
                    <div className="start-menu-content">
                        {apps.map(app => (
                            <div key={app.name} onClick={() => openWindow(app.name)}>
                                <Icon name={app.name} icon={app.icon} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="taskbar-icons">
                {apps.map(app => (
                    <img key={app.name} src={`images/icons/${app.icon}.svg`} alt={app.name} title={app.name} onClick={() => openWindow(app.name)} />
                ))}
            </div>
            <div className="taskbar-clock">
                <LanguageSelector />
                {currentTime}
            </div>
        </div>
    );
}

function getFormattedDate() {
    const date = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    return date.toLocaleString('en-US', options);
}

export default Taskbar;