// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desktop {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
}

.desktop-icons {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Desktop/Desktop.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,kBAAkB;IAClB,yDAA8D;IAC9D,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,2BAA2B;IAC3B,SAAS;AACb","sourcesContent":[".desktop {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    position: relative;\n    background-image: url(\"../../../public/images/background.jpg\");\n    background-size: cover;\n    background-position: center;\n}\n\n.desktop-icons {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: flex-start;\n    justify-content: flex-start;\n    gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
