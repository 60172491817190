import React from 'react';
import {getLang} from "../../config/Translator/Translator";

function LanguageSelector() {
    const changeLanguage = (event) => {
        event.preventDefault();
        const lang = event.target.value;
        window.location.href = `?lang=${lang}`;
    };

    const currentLang = getLang();

    return (
        <select value={currentLang} onChange={changeLanguage}>
            <option value="en">English</option>
            <option value="fr">Français</option>
        </select>
    );
}

export default LanguageSelector;