import React from 'react';
import './Icon.css';

function Icon({ name, icon }) {
    return (
        <div className="icon">
            <img src={`images/icons/${icon}.svg`} alt={name} />
            <span>{name}</span>
        </div>
    );
}

export default Icon;
