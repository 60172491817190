import React, {useState} from 'react';
import {Rnd} from 'react-rnd';
import './Window.css';

function Window({title, width, height, content, onClose, zIndex, bringToFront}) {
  const [isResponsive, setIsResponsive] = useState(false);

  const handleResize = (e, direction, ref, delta, position) => {
    ref.style.width = `${ref.offsetWidth}px`;
    ref.style.height = `${ref.offsetHeight}px`;
    if (ref.offsetWidth < 700) {
      setIsResponsive(true);
    } else {
      setIsResponsive(false);
    }
  };

  return (
      <Rnd
          default={{
            x: document.querySelector(".App").getBoundingClientRect().width/ 2 - width / 2,
            y: document.querySelector(".App").getBoundingClientRect().height/ 2 - height / 2 - 40,
            width: width,
            height: height,
          }}
          minWidth={350}
          minHeight={350}
          bounds="parent"
          className={`window ${isResponsive ? 'responsive' : ''}`}
          style={{zIndex}}
          onDragStart={bringToFront}
          onMouseDown={bringToFront}
          onResize={handleResize}
          dragHandleClassName="window-title-bar"
      >
        <div className="window-title-bar" onMouseDown={bringToFront}>
          <span>{title}</span>
          <button className="close-button" onClick={onClose}><img
              src={"images/icons/x-circle.svg"} alt="close"/></button>
        </div>
        <div className="window-content"
             dangerouslySetInnerHTML={{__html: `<div class="content">${content}</div>`}}/>
      </Rnd>
  );
}

export default Window;