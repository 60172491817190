import {getTranslation} from './Translator/Translator';

let appsData = getTranslation();

const createEducationContent = () => {
    let content = '';
        content += `
      <h1>${appsData.education.title}</h1>
     <ul class="timeline">
    `;

    appsData.education.content.forEach((education, index) => {
       content += `
    <li>
    <div class="event">
        <div class="event-content">
            <img src="${education.img}" alt="${education.title}">
            <h2>${education.title}</h2>
            <span class="date">${education.date}</span>
            <span class="date">${education.graduated}</span>
            <p>${education.location}</p>
            <p>${education.description}</p>
        </div>
    </div>
  </li>
    `;
    });

    content += '</ul>';
    return content;
}

const createProContent = () => {
    let content = '';
    content += `
      <h1>${appsData.experiences.title}</h1>
     <ul class="timeline">
    `;

    appsData.experiences.content.forEach((experience, index) => {
        content += `
    <li>
    <div class="event">
        <div class="event-content">
            <img src="${experience.img}" alt="${experience.title}">
            <h2>${experience.title}</h2>
            <h3 class="date">${experience.company}</h3>
            <h4 class="date">${experience.type}</h4>
            <span class="date">${experience.date}</span>
            <p>${experience.location}</p>
            <p>${experience.description}</p>
        </div>
    </div>
  </li>
    `;
    });

    content += '</ul>';
    return content;
}

const createAssoContent = () => {
    let content = '';
    content += `
      <h1>${appsData.associative.title}</h1>
     <ul class="timeline">
    `;

    appsData.associative.content.forEach((experience, index) => {
        content += `
    <li>
    <div class="event">
        <div class="event-content">
            <img src="${experience.img}" alt="${experience.title}">
            <h2>${experience.title}</h2>
            <span class="date">${experience.date}</span>
            <p>${experience.location}</p>
            <p>${experience.description}</p>
        </div>
    </div>
  </li>
    `;
    });

    content += '</ul>';
    return content;
}

const createSkillContent = () => {
    let content = '';
    content += `
      <h1>${appsData.skills.title}</h1>
    `;

    appsData.skills.content.forEach(skillLine => {
       content += `
           <div class="skill-line">
               <h2>${skillLine.title}</h2>
               <ul>
                     ${skillLine.skills.map(skill => `<li style="background-color: ${skillLine.color}"><span>${skill}</span></li>`).join('')}
               </ul>
           </div> 
       `;
    });

    return content;
}

window.clickTab = (index) => {

    const tabs = document.querySelectorAll('li[id^="tab-"]');

    tabs.forEach(tab => {
        if (tab.id === "tab-" + index) {
            tab.classList.add("selected");
        } else {
            tab.className = '';
        }
    });

    let content = '';

    content += `
        <div id='tab-content'>
            <div class="project-card">
                <h2>${appsData.projects.content[index].title}</h2>
                <div>
                    <img src="${appsData.projects.content[index].img}" alt="${appsData.projects.content[index].title}">
                    <div>
                        <p>${appsData.projects.content[index].date}</p>
                        <p>${appsData.projects.content[index].description}</p>
                        `;
    appsData.projects.content[0].technologies.forEach(technology => {
        content += `<span class="tag">${technology}</span>`;
    });
    content += `
                    </div>
                </div>
            </div>
        </div>
    `;

    document.getElementById('tab-content').innerHTML = content;
}

const createProjectContent = () => {
    let content = '';

        content += `<h1>${appsData.projects.title}</h1><div class='tabs'><ul>`;
    appsData.projects.content.forEach((project, index) => {
        content += `<li onclick="clickTab(${index})" id="tab-${index}" class="${index === 0 ? "selected" : ""}"><span>${project.title}</span></li>`;
    });
    content += `</ul>`;

    content += `
        <div id='tab-content'>
            <div class="project-card">
                <h2>${appsData.projects.content[0].title}</h2>
                <div>
                    <img src="${appsData.projects.content[0].img}" alt="${appsData.projects.content[0].title}">
                    <div>
                        <p>${appsData.projects.content[0].date}</p>
                        <p>${appsData.projects.content[0].description}</p>
                        `;
    appsData.projects.content[0].technologies.forEach(technology => {
        content += `<span class="tag">${technology}</span>`;
    });
    content += `
                    </div>
                </div>
            </div>
        </div>
    `;

    return content;
}

const createHobbiesContent = () => {
    let content = '';

content += `
            <h1>${appsData.hobbies.title}</h1>
            <div class="explorer">
                <div class="Breadcrumb">
                    <span>/home/vrameau/documents/${appsData.hobbies.title}</span>
                </div>
                <div class="explorer-content">
                    `;
    appsData.hobbies.content.forEach(hobby => {
        content += `
                    <div class="folder">
                        <img src="/images/icons/folder.svg" alt="folder" class="folder-icon">
                        <img src="${hobby.img}" alt="folder" class="hobby-icon">
                        <h3>${hobby.title}</h3>
                    </div>
        `;
    });
    content += `
                </div>
            </div>
        `;

    return content;
}

const apps = [
    {
        name: appsData.about.title,
        icon: 'user',
        content: `
      <h1>${appsData.about.title}</h1>
      <div class="card">
          <div class="profil-img"> </div>
          <div class="info">
              <p>${appsData.about.content.name}</p>
              <p>${appsData.about.content.situation}</p>
              <p>${appsData.about.content.motivations}</p>
              <p>${appsData.about.content.goal}</p>
              <a href="/docs/${appsData.about.content.resume_link}.pdf" target="_blank">${appsData.about.content.resume}</a>
          </div>
      </div>
    `,
        width: window.screen.width / 2,
        height: window.screen.height * 3 / 5
    },
    {
        name: appsData.education.title,
        icon: 'book-open',
        content: createEducationContent(),
        width: window.screen.width / 3,
        height: window.screen.height * 2 / 3
    },
    {
        name: appsData.experiences.title,
        icon: 'briefcase',
        content: createProContent(),
        width: window.screen.width / 3,
        height: window.screen.height * 2 / 3
    },
    {
        name: appsData.associative.title,
        icon: 'smile',
        content: createAssoContent(appsData.associative),
        width: window.screen.width / 3,
        height: window.screen.height * 2 / 3
    },
    {
        name: appsData.skills.title,
        icon: 'settings',
        content: createSkillContent(),
        width: window.screen.width * 2 / 3,
        height: window.screen.height * 2 / 3
    },
    {
        name: appsData.projects.title,
        icon: 'code',
        content: createProjectContent(),
        width: window.screen.width / 2,
        height: window.screen.height * 2 / 3
    },
    {
        name: appsData.hobbies.title,
        icon: 'film',
        content: createHobbiesContent(),
        width: window.screen.width / 2,
        height: window.screen.height * 3 / 4
    },
    {
        name: appsData.contact.title,
        icon: 'mail',
        content: `
        <div class="success">
        <p>${appsData.contact.successMessage}</p>
        </div>
        <div class="error">
        <p></p>
        </div>
        <div class="contact">
            <h1>${appsData.contact.title}</h1>
            <label for="email">${appsData.contact.email}:</label><br>
            <input type="email" id="email" name="email" placeholder="${appsData.contact.mailExample}" required><br>
            <label for="subject">${appsData.contact.subject}:</label><br>
            <input type="text" id="subject" name="subject" placeholder="${appsData.contact.subjectExample}" required><br>
            <label for="message">${appsData.contact.message}:</label><br>
            <textarea id="message" name="message" rows="4" cols="50" placeholder="${appsData.contact.messageExample}" required></textarea><br>
            <button onclick="onSubmitContact()">${appsData.contact.send}</button>
        </div>
    `,
        width: window.screen.width / 4,
        height: window.screen.height * 6 / 10
    }
];

window.onSubmitContact = () => {


    let email = document.getElementById('email').value;
    let subject = document.getElementById('subject').value;
    let message = document.getElementById('message').value;
    if(!email || !subject || !message || email === "" || subject === "" || message === "") {
        document.querySelector('.error').style.opacity = 1;
        document.querySelector('.error > p').innerHTML = appsData.contact.errorMessage;

        setTimeout(() => {
            document.querySelector('.error').style.opacity = 0;
        }, 5000);
        return;
    }

    if(email.indexOf('@') === -1 || email.indexOf('.') === -1) {
        document.querySelector('.error').style.opacity = 1;
        document.querySelector('.error > p').innerHTML = appsData.contact.mailError;

        setTimeout(() => {
            document.querySelector('.error').style.opacity = 0;
        }, 5000);
        return;
    }
    // const webhookURL = 'https://discord.com/api/webhooks/1254800579083898923/HXfT6zLL7ONUC0Mg4y0P--qG_Wb0Oq_IjUDRSvmdfP7f00uxdcxPm951ma9b2RrblToI';
    // const payload = {
    //     username: email,
    //     content: '----------------------------------------------------\nsujet : ' + subject + '\n' + message+'\ncontact : ' + email,
    // };

    let webhookURL = `https://n8n.kastaforia.fr/webhook/ce433896-fac9-43d8-9ba3-d1bd97b41e86?email=${email}&subject=${subject}&message=${message}`;

    fetch(webhookURL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        // body: JSON.stringify(payload)
    })
        .then(response => {
            if (response.ok) {
                document.querySelector('.success').style.opacity = 1;
                document.getElementById('email').value = '';
                document.getElementById('subject').value = '';
                document.getElementById('message').value = '';

                setTimeout(() => {
                    document.querySelector('.success').style.opacity = 0;
                }, 5000);
            } else {
                throw new Error('Erreur lors de l\'envoi du message');
            }
        })
        .catch(error => {
            console.error('Erreur lors de l\'envoi du message :', error);
        });
}

export default apps;