import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import Taskbar from '../Taskbar/Taskbar';
import Window from '../Window/Window';
import apps from '../../config/apps';
import './Desktop.css';

function Desktop() {
    const [openWindows, setOpenWindows] = useState([]);
    const [windowOrder, setWindowOrder] = useState([]);

    const openWindow = (name) => {
        if (!openWindows.includes(name)) {
            setOpenWindows([...openWindows, name]);
        }
        setWindowOrder([...windowOrder.filter(win => win !== name), name]);
    };

    const closeWindow = (name) => {
        setOpenWindows(openWindows.filter(win => win !== name));
        setWindowOrder(windowOrder.filter(win => win !== name));
    };

    return (
        <div className="desktop">
            <div className="desktop-icons">
                {apps.map(app => (
                    <div key={app.name} onClick={() => openWindow(app.name)}>
                        <Icon name={app.name} icon={app.icon} />
                    </div>
                ))}
            </div>
            {apps.map(app => (
                openWindows.includes(app.name) && (
                    <Window
                        key={app.name}
                        title={app.name}
                        width={app.width}
                        height={app.height}
                        content={app.content}
                        onClose={() => closeWindow(app.name)}
                        zIndex={windowOrder.indexOf(app.name) + 1}
                        bringToFront={() => setWindowOrder([...windowOrder.filter(win => win !== app.name), app.name])}
                    />
                )
            ))}
            <Taskbar openWindow={openWindow} />
        </div>
    );
}

export default Desktop;