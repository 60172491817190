export const datasEn = {
    about: {
        title: "About me",
        content: {
            name: "My name is Valentin RAMEAU and I'm a developer based in Bordeaux.",
            situation: "I am currently completing a master's degree in MIAGE (Méthodes Informatiques Appliquées à la gestion des entreprises) at the University of Bordeaux.",
            goal: "I am looking for a permanent contract after graduating in October 2024.",
            motivations: "I've been passionate about IT and especially development for many years. What interests me most is always learning new things.",
            resume: "this is my cv",
            resume_link: "CV-en"
        }
    },
    education: {
        title: "School career",
        content: [
            {
                title: "Master's degree in MIAGE",
                date: "2022 - 2024",
                graduated: "Ongoing",
                location: "University of Bordeaux, Talence, France",
                description: "The master's degree MIAGE (Méthodes Informatiques Appliquées à la Gestion des Entreprises) provides a dual skill set in both IT and management, enabling graduates to become experts in IT and project management.",
                img: "/images/miage.png"
            },
            {
                title: "Bachelor's degree 3",
                date: "2021 - 2022",
                graduated: "Graduated in 2022",
                location: "Université de Bordeaux, Talence, France",
                description: "The Bachelor's degree 3 in computer science, MIAGE specialization (Méthodes Informatiques Appliquées à la Gestion des Entreprises) course is the entry point to the MIAGE master's degree and enables students to acquire the skills required for the MIAGE master's degree.",
                img: "/images/miage.png"
            },
            {
                title: "BTS SIO, SLAM specialization",
                date: "2019 - 2021",
                graduated: "Graduated in 2021",
                location: "High school Bertran de Born, Périgueux, France",
                description: "The BTS SIO (Services Informatiques aux Organisations) trains IT technicians. The SLAM (Solutions Logicielles et Applications Métiers) specialization trains technicians capable of developing, deploying and maintaining business applications.",
                img: "/images/bts.png"
            }
        ]
    },
    experiences: {
        title: "Professional experiences",
        content: [
            {
                title: "Work-study student web developer at Smile",
                company: "Smile",
                type: "Work-study",
                date: "2022-2024 (Ongoing)",
                location: "Bordeaux, France",
                description: "Work-study program during the Master's degree MIAGE as a php and drupal web developer. I was able to work on projects of various sizes.",
                img: "/images/smile.png"
            },
            {
                title: "Business Analyst Intern at Sopra Steria",
                company: "Sopra Steria",
                type: "Internship",
                date: "Avril 2022 - Juin 2022",
                location: "Mérignac, France",
                description: "Internship at the end of my Bachelor's degree 3 MIAGE as a Business Analyst. I was able to work on technical design and the design of business functionalities.",
                img: "/images/sopra.png"
            },
            {
                title: "Web developer intern at the polyclinic Francheville",
                company: "polyclinic Francheville",
                type: "Internship",
                date: "February 2021 - March 2021",
                location: "Périgueux, France",
                description: "Internship during the second year of BTS SIO as a web developer. I was able to work on the realization of a visualization tool of the Wi-Fi terminals within the framework of the administration of the IT of the polyclinic.",
                img: "/images/francheville.png"
            },
            {
                title: "Web developer intern at DMAI",
                company: "DMAI",
                type: "Internship",
                date: "Juin 2020 - June 2020",
                location: "Sainte-Alvère, France",
                description: "Internship during the first year of BTS SIO as a WEB developer. I was able to work on the creation of a website containing a dashboard to monitor the activity of an electronic board production line.",
                img: "/images/dmai.png"
            }
        ]
    },
    associative: {
        title: "Associative experiences",
        content: [
            {
                title: "Vice-President in charge of Junior-Enterprises",
                date: "2024-2025",
                location: "France",
                description: "Vice-president of MIAGE Connection, in charge of monitoring and supporting the Junior-Enterprises of the Junior MIAGE Concept label.",
                img: "/images/mc.png"
            },
            {
                title: "Junior MIAGE Concept Bordeaux, in charge of research and sales",
                date: "2024",
                location: "Talence, France",
                description: "My mission is to monitoring projects.",
                img: "/images/jmc.png"
            },
            {
                title: "President of Junior MIAGE Concept Bordeaux",
                date: "2022 - 2024",
                location: "Talence, France",
                description: "President of Junior MIAGE Concept Bordeaux. My mission was to represent the association, define the strategy and ensure its follow-up, as well as to support the various members of the association in their tasks.",
                img: "/images/jmc.png"
            },
            {
                title: "General Secretary and Human Resources Manager of Junior MIAGE Concept Bordeaux",
                date: "2022",
                location: "Talence, France",
                description: "As General Secretary, my role was to ensure the smooth running of the archives and the administrative life of the association. As Human Resources Manager, I was responsible for monitoring the association's members, from the moment they joined until the end of their mandate, whether they were administrators, project managers or consultants.",
                img: "/images/jmc.png"
            },
            {
                title: "contributor for Junior MIAGE Concept Bordeaux",
                date: "2021-2022",
                location: "Talence, France",
                description: "As a contributor for Junior MIAGE Concept Bordeaux, I had the task of creating a WordPress website used as an internal tool for the CFA of the University of Bordeaux.",
                img: "/images/jmc.png"
            },
            {
                title: "create and organize an annual festival of popular culture : Kawaii Festival at Bergerac",
                date: "2017-2021",
                location: "Bergerac, France",
                description: "I was able to participate in the creation then organization of 3 editions of the Kawaii Festival in Bergerac. This event aims to promote Japanese popular culture as well as to propose new activities for the Young people of Bergerac.",
                img: "/images/asso-nemiku.jpg"
            }
        ]
    },
    skills: {
        title: "Skills",
        content: [
            {
                title: "languages",
                color: "#9A031E",
                skills: [
                    "HTML",
                    "CSS",
                    "PHP",
                    "Javascript",
                    "C",
                    "C++",
                    "C#",
                    "Java",
                    "Python"
                ]
            },
            {
                title: "Frameworks and infrastructures",
                color: "#7D092F",
                skills: [
                    "Symfony",
                    "Drupal",
                    "NodeJS",
                    "React",
                    "Express"
                ]
            },
            {
                title: "Database",
                color: "#5F0F40",
                skills: [
                    "SQL",
                    "MySQL/MariaDB",
                    "postgreSQL"
                ]
            },
            {
                title: "Tools",
                color: "#4B1F47",
                skills: [
                    "Git",
                    "Docker",
                    "Unity",
                    "Blender"
                ]
            },
            {
                title: "Project management",
                color: "#372E4E",
                skills: [
                    "Agile Method",
                    "Scrum"
                ]
            },
            {
                title: "Others",
                color: "#0F4C5C",
                skills: [
                    "Audit",
                    "Project management",
                    "Strategy creation and management",
                    "Implementation and monitoring of a QMS"
                ]
            }
        ]
    },
    projects: {
        title: "Projects",
        content: [
            {
                title: "EasyBudget",
                date: "In progress",
                img: "/images/easybudget.png",
                description: "Development of a budget management and monitoring application.",
                technologies: [
                    "Nodejs",
                    "Html",
                    "Css",
                    "TypeScript",
                    "JavaScript",
                    "Express",
                    "Sequelize"
                ]
            },
            {
                title: "Portfolio",
                date: "2024",
                img: "/images/here.png",
                description: "Creating my portfolio as a web page.",
                technologies: [
                    "Nodejs",
                    "Html",
                    "Css",
                    "JavaScript",
                    "React"
                ]
            },
            {
                title: "GameFlow",
                date: "2024",
                img: "/images/gameflow.png",
                description: "Realization of a game rules simulation application in the form of a workflow. This tool enables better design and balancing for Game Designers. This project was carried out during the Master 2 MIAGE.",
                technologies: [
                    "Nodejs",
                    "Html",
                    "Css",
                    "JavaScript",
                    "Sequelize",
                    "MariaDB",
                    "Express"
                ]
            },
            {
                title: "Corporate Conquest",
                date: "2024",
                img: "/images/cc.png",
                description: "Game created during Master 2 MIAGE. This game is a management and deckbuilding game. The aim is to manage a company and make it prosper by buying cards that represent employees or items.",
                technologies : [
                    "Nodejs",
                    "Html",
                    "Css",
                    "JavaScript",
                    "Sequelize",
                    "MariaDB",
                    "Express"
                ]
            }
        ]
    },
    hobbies: {
        title: "Hobbies",
        content: [
            {
                title: "Video games",
                img: "/images/icons/controller.svg"
            },
            {
                title: "Board games",
                img: "/images/icons/dice.svg"
            },
            {
                title: "Role-playing games",
                img: "/images/icons/shield.svg"
            },
            {
                title: "Reading",
                img: "/images/icons/book.svg"
            },
            {
                title: "Writing",
                img: "/images/icons/pen.svg"
            },
            {
                title: "Theater",
                img: "/images/icons/mask.svg"
            },
            {
                title: "Cinema",
                img: "/images/icons/film.svg"
            }
        ]
    },
    contact: {
        title: "Contact me",
        email: "Your email",
        subject: "Your subject",
        message: "Your message",
        mailExample: "test@test.fr",
        subjectExample: "example",
        messageExample: "Mail example",
        successMessage: "Mail send successfully",
        errorMessage: "Please fill in all fields correctly",
        mailError: "Please enter a valid email address",
        send: "Send"
    },
    days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday"
    ]
}